var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"img_box",attrs:{"src":_vm.storyData[this.$route.params.id - 1].imgURl,"alt":""}}),_c('div',{staticClass:"story_box"},[_c('div',{staticClass:"story_title"},[_vm._v(" "+_vm._s(_vm.storyData[this.$route.params.id - 1].title)+" ")]),_c('div',{staticClass:"story_content"},_vm._l((_vm.storyData[this.$route.params.id - 1].content),function(item,index){return _c('div',{staticClass:"paragraph",class:{
          double_p: index % 2 > 0,
          mobile_p: _vm.$isMobile,
          single_mobile_p: _vm.$isMobile && index % 2 == 0,
          double_mobile_p: _vm.$isMobile && index % 2 > 0,
        }},[(!(_vm.$isMobile && index % 2 > 0))?_c('img',{staticClass:"p_icon",attrs:{"src":index % 2 > 0
              ? require('../assets/images/double.png')
              : require('../assets/images/single.png'),"alt":""}}):_c('img',{staticClass:"mobile_icon",attrs:{"src":require("../assets/images/right_icon.png"),"alt":""}}),_vm._v(" "+_vm._s(item)+" ")])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }