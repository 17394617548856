<template>
  <section class="w-full fixed top-0 z-50 bg-[rgba(0,0,0,0.2)]" >
    <div  class="nav_box mx-auto flex justify-around !<xl:text-12px" :class="{ 'navActive': navActived,'flex-center': $isMobile}">
      <img @click="toRouter(1)" src="../assets/images/white_logo.png" alt="" class="logo" />
      <img v-show="$isMobile" @click="showNav" class="nav_btn" src="../assets/images/nav_icon_more.png" alt="">
      <!-- !sm:text-12px -->
      <div v-show="!$isMobile" class="dp-f jc-c nav_item_box">
        <div v-for="item in navItems" @click="toRouter(item.id)" class="nav_item"  @mouseout="outStyle()" @mouseover="hoverStyle()">
          {{ item.name }}
          <div class="menu">
            <img class="select_pic" src="../assets/images/select_pic.png" alt="" />
            <div class="drop" v-for="it in item.options">
              <a :href="'#' + it.key">{{ it.val }}</a>
            </div>
          </div>
        </div>
      </div>
      <div v-show="$isMobile&&mobileShow" class="mobile_nav_box">
        <img class="phone_icon" src="../assets/images/phone_icon.png" alt="">
        <div class="nav_title" v-for="item in navItems" @click="toRouter(item.id)">{{item.name}}
          <div class="nav_item_title" v-for="it in item.options">{{it.val}}</div>
        </div>
      </div>
    </div>
    <div v-show="!navActived" class="line"></div>
  </section>
</template>

<script>
export default {
  name: 'Nav',
  props: {
    // navItems: Array
  },
  data () {
    return {
      mobileShow: false,
      navActived: false,
      navItems: [
        {
          id: 1,
          name: '关于零壹',
          options: [
            {
              key: 'info',
              val: '公司简介'
            },
            {
              key: 'culture',
              val: '企业文化'
            },
            {
              key: 'team',
              val: '团队氛围'
            },
            {
              key: 'story',
              val: '员工故事'
            }
          ],
        },
        {
          id: 2,
          name: '产品服务',
          options: [
            {
              key: 'chamet',
              val: '社交'
            },
            {
              key: 'gemgala',
              val: '网赚'
            },
            {
              key: 'game',
              val: '游戏'
            },
            {
              key: 'server',
              val: '服务'
            }
          ],
        },
        {
          id: 3,
          name: '加入我们',
          options: [
            {
              key: '',
              val: '技术开发岗'
            },
            {
              key: '',
              val: '产品运营岗'
            },
            {
              key: '',
              val: '市场营销岗'
            },
            {
              key: '',
              val: '管培生'
            }
          ],
        },
        {
          id: 4,
          name: '联系我们',
          options: !this.$isMobile ?[
            {
              key: '',
              val: '商务合作'
            },
            {
              key: '',
              val: '招聘相关'
            },
            {
              key: '',
              val: '公司地址'
            },
            {
              key: '',
              val: '合作伙伴'
            }
          ]:[],
        },
      ],
    }
  },
  mounted() {
    // if(this.$isMobile) {
    //   this.mobileShow=true
    // }
  },
  methods: {
    hoverStyle () {
      this.navActived = true
    },
    outStyle () {
      this.navActived = false
    },
    toRouter(id) {
      this.mobileShow = false
      if(id === 1) {
      this.$router.push('/')
      }else if(id === 2) {
      this.$router.push('product')
      }else if(id === 3) {
        this.$router.push('join_us')
      }else if(id === 4) {
        this.$router.push('contact')
      }
    },
    showNav() {
      this.mobileShow = !this.mobileShow
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.line {
  height: 1px;
  background: linear-gradient(325deg, rgba(255,255,255,0) 0%, #FFFFFF 48%, rgba(255,255,255,0) 100%);
}
.nav_box {
  @media (max-width: 700px) {
    height: 0.44rem;
    padding-top: 0px;
    line-height: 0.44rem;
  }
  padding-top: 0.21rem;
  // width: 100%;
  height: 0.9rem;
  // align-items: center;
  // background: rgba(0, 0, 0, 0.2);
  // position: fixed;

  .logo {
    @media (max-width: 700px) {
      width: 0.81rem;
      height: 0.21rem;
    }
    width: 1.8rem;
    height: 0.48rem;
    cursor: pointer;
    // position: absolute;
    // top: 0.26rem;
    // left: 2.8rem;
  }

  .nav_item_box {
    // @media (min-width: 640px) {
    //   font-size: 12px;
    // }
    width: max-content;
    // line-height: 0.45rem;
    height: 0.42rem;
    // position: absolute;
    // right: 2.7rem;
    // top: 0.28rem;
    font-size: 0.18rem;
    font-weight: 500;
    color: #fff;
    padding-top: 0.14rem;
  }

  .nav_item {
    width: max-content;
    height: 0.25rem;
    padding-right: 0.5rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    font-weight: normal;
    text-align:  center;
  }

  .menu {
    padding-top: 0.05rem;
  }

  .select_pic {
    width: 0.72rem;
    height: 0.12rem;
    margin: 0 auto;
  }

  // .m
  .nav_item:hover {
    overflow: visible;
  }

  .drop {
    line-height: 0.7rem;
    text-align: center;
  }

  .drop:hover {
    color: #DE6960;
    font-weight: 600;
  }
}

.navActive {
  height: 4rem;
}
.mobile_nav_box {
  width: 1.5rem;
  // height: 4.7rem;
  background: #fff;
  position: absolute;
  top: 0.57rem;
  right: 0.12rem;
  box-shadow: 0px 3px 8px 0px rgba(75,148,223,0.15);
  border-radius: 3px;
  text-align: center;
  line-height: 0.3rem;
  font-size: 0.12rem;
  padding: 0.1rem 0;
  box-sizing: border-box;
}
.phone_icon {
  width: 0.12rem;
  height: 0.06rem;
  position: absolute;
  top: -0.06rem;
  right: 0.15rem;
}
.nav_title {
  font-weight: 600;
  color: #185DFF;
}
.nav_item_title {
  color: #183266;
}
.nav_btn {
  width: 0.18rem;
  height: 0.18rem;
  position: absolute;
  right: 0.25rem;
}
</style>
