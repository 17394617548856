<template>
  <div class="flex-center footer_box">
    <div>
      <img
        v-show="!$isMobile"
        class="logo"
        src="../assets/images/blue_logo.png"
        alt=""
      />
      <div v-show="$isMobile" class="footer_content">
        杭州零壹比特科技有限公司
      </div>
      <div class="footer_content">
        copyright @ 2022-2024
        <span v-show="!$isMobile">杭州零壹比特科技有限公司</span> 版权所有｜
        <a href="https://beian.miit.gov.cn">浙ICP备2022030886号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  mounted() {
    console.log(window.location.href)
  },
}
</script>

<style lang="less" scoped>
// 底部
.footer_box {
  @media (max-width: 700px) {
    height: 0.79rem;
    padding: 0;
  }
  justify-content: space-around;
  width: 100%;
  height: 2.79rem;
  background: #fff;
  // padding: 0 2.8rem;
  text-align: center;
  border-top: 1px solid #f6f6f6;
  .logo {
    @media (max-width: 700px) {
      width: 0.36rem;
      height: 0.11rem;
    }
    margin: 0 auto 0.17rem;
    width: 1.5rem;
    height: 0.4rem;
  }
}
.footer_content {
  @media (max-width: 700px) {
    font-size: 0.2rem;
    zoom: 0.5;
    line-height: 0.3rem;
    font-weight: normal;
  }
  font-size: 0.16rem;
  font-weight: 500;
  color: #79849b;
  line-height: 40px;
}
</style>
