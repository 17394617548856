<template>
  <div>
    <img
      class="img_box"
      :src="storyData[this.$route.params.id - 1].imgURl"
      alt=""
    />
    <div class="story_box">
      <div class="story_title">
        {{ storyData[this.$route.params.id - 1].title }}
      </div>
      <div class="story_content">
        <div
          v-for="(item, index) in storyData[this.$route.params.id - 1].content"
          class="paragraph"
          :class="{
            double_p: index % 2 > 0,
            mobile_p: $isMobile,
            single_mobile_p: $isMobile && index % 2 == 0,
            double_mobile_p: $isMobile && index % 2 > 0,
          }"
        >
          <img
            v-if="!($isMobile && index % 2 > 0)"
            class="p_icon"
            :src="
              index % 2 > 0
                ? require('../assets/images/double.png')
                : require('../assets/images/single.png')
            "
            alt=""
          />
          <img
            v-else
            class="mobile_icon"
            src="../assets/images/right_icon.png"
            alt=""
          />
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmployeeStory',
  data() {
    return {
      storyData: [
        {
          imgURl: require('../assets/images/gushi_pic3.png'),
          title: '“不设限”，职场小白变身优秀运营——产品运营部缑智昊',
          content: [
            '缑同学，零壹比特在你心目中是一家什么样的公司呢？',
            '在知乎上其实很少刷到关于零壹比特的信息，今天我也特别想介绍下这家小而美的互联网公司。初次听到零壹比特，可能会以为我们是一家区块链公司，其实我们的主要赛道是出海社交。',
            '那为什么叫零壹比特呢？',
            '取名零壹比特，是因为在计算机语言中，01是计算机唯一能识别的两个字符，比特bit则是信息处理的最小单位，寓意企业像计算机一样从01开始学习，逐步演化为超智能体。',
            '零壹比特目前主要运营的产品有哪些呢？',
            '我所在的海外项目部主要负责运营一款出海泛娱乐社交产品，作为较早踏上出海之路的产品，长期占领南亚、东南亚、中东地区社交产品畅销榜榜首。',
            '好的，可以和我们分享下在零壹比特的新人体验如何吗？',
            '新人体验啊，当然非常棒呀，不然我怎么会呆了一年以上呢。（此处应有掌声。。）',
            '那在新人体验过程中，你比较喜欢哪部分环节呢？',
            'leader和mentor对新人带教的环节吧，我觉得这个对新人，特别是应届生来说是特别需要的，也是特别有用的。比如可以帮助新人更快地融入团队，解决一些工作中遇到的难题，当然也可以直接向上提出要资源的要求哈哈。',
            '现在很多同学其实更倾向于大厂的机会，那你觉得相比大厂，零壹比特有哪些优势吗？',
            '怎么说呢，大厂的优势在于严丝合缝的组织形式和长久以来沉淀的成熟方法论，零壹比特的优势则在于不受限制、野蛮生长的开放环境。如果你是一个具有自驱力和学习力的人，我非常推荐你选择零壹比特，开拓一片属于自己的天地。公司有不少技术和产品大牛，能力强人也很nice，不论你是刚入职的技术还是运营，只要有问题，都可以直接去工位找他们答疑。如果你有灵光乍现的想法，准备好你的ppt，约大家在会议室碰一下，说不定你的想法就可以在未来某一天落地。',
            '那加入零壹比特后，新人有没有能快速“奔现”的机会呢？',
            '这个。。只要你有能力，只有你愿意，一切皆有可能吧哈哈。。其实公司很重视新人的能力展示，在我们这叫“亮相”。leader会在你入职后主动帮你争取展示机会，我有幸在还未转正时在全公司范围做了会员体系的竞品分析，一年后还参与了产品会员体系的落地工作。在这里，只要你能抓住机会，就能获得职业生涯的攀升。',
            '听起来确实是一家很nice的公司哦，那你觉得在零壹比特会不会有很大的压力？',
            '想要成为优秀的人，那压力肯定多少都会有的。但零壹比特不会特别为员工设置工作指标， 在多数互联网公司，员工都会担负kpi或是okr指标。在零壹比特，只有针对整个团队提出的收入、用户量等指标，这意味着每个人可以真正全情投入手头的工作，而不用为个人kpi犯愁。',
            '零壹比特的“秃头小哥哥”多吗，哈哈开个玩笑，就是想了解下有没有什么996的加班文化吗？',
            '我们不提倡无必要的加班，更没有到点不能走的隐性加班文化。只要保证手头工作的按时交付，下班后的8个小时完全可以自由支配。我一般会利用闲暇时间去公司健身房健身或者参加每周的泡会活动， 提升自己的同时拿到泡会补贴，一举两得。',
            '聊了这么多，很想了解下零壹比特的文化是怎么样的呢？',
            '我觉得首要提倡共赢文化，比如疫情时期，海外项目为上万海外女用户解决了就业问题，以用户反馈指导产品迭代，在提升产品体验同时扶植优质用户，达成产品与用户的共赢。此外，公司还强调企业与员工的共赢，为员工提供可观的薪资福利和成长机会，这点放在最后说。零壹比特还提倡精英文化，拒绝培养岗位的螺丝钉，鼓励每位员工在专精的领域之外寻求更多成长机会。我刚入职在做比较基础的客服和审核工作，一年后已经可以独立负责小的产品模块和内容风控业务，这样的成长机会在很多大厂也很难遇到。',
            '最后想帮大家了解下最想了解的问题，你猜是什么呢哈哈哈。',
            '那肯定是薪酬福利啊！这块我觉得真的非常非常OK！零壹比特一向求贤若渴，公司体量虽离大厂还有段距离，但对优秀人才开价绝对不输大厂。 薪资这么给力，年终奖也拖不了后腿。之前提过零壹比特最重要的kpi是项目的收入，如果成功完成年度指标，会有可观的年终奖发放给各部门进行分配。我有幸在海外项目蓬勃发展阶段加入零壹比特，去年拿到的年终奖放到现在可以买很多部iPhone14pro了。对于一个当时参加工作不久的应届生来说，真香！ 除开丰厚的薪资报酬，还有一年两次的大型团建活动和每季度一次的小型团建活动可以参加。大型团建一般是周年庆和年会，小型团建则是登山、轰趴等户外活动。每年周年庆和年会都会有抽奖活动，一等奖可以抽到新款华为手机或是电脑，不自用的话挂咸鱼又是一笔不小的收入。',
          ],
        },
        {
          imgURl: require('../assets/images/gushi_pic1.png'),
          title: '出魔都，弃阿里，和公司一同成长——技术部陈章',
          content: [
            '章章总的头发貌似茂盛了许多呀，就是这个肚腩有点过分了啊哈哈！',

            '害，别提了，我已经在控制饮食，努力健身运动了，现在恨不得天天泡在公司的健身房里。',

            '哈哈章章总加油了！言归正传，听说章章总以前是在魔都工作的，为啥选择来到美丽的西子湖畔呢？',

            '我是在2018年5月辞去了原来上海的工作，主要是因为当时女朋友（现任老婆）在杭州上学。',

            '原来是因为“章嫂”呀，果然是“为爱几百里奔赴”，那为什么会选择零壹比特呢？',

            '其实当时我还有阿里、海康等大厂的offer，最终选择零壹比特的时候，亲朋好友也不是特别理解，最终证明我的选择是正确的。我选择零壹比特有几个原因吧，首先是在和漂亮的HR小姐姐的沟通过程中发现，虽然面试前未曾见过，但她会帮你考虑到各种问题，细致到车辆限行、上下班路线等问题，让你感受到公司对人才的重视。其次就是面试流程，虽然经历了3轮面试，但秉着“让候选人只跑一次”的原则， 我只花了一个上午的样子，和阿里（三入杭州，经历了五轮面试）有很大的对比。面试环节，无论是当时的负责人还是技术总监，在我们的沟通过程中，都展示了很强的专业能力，整体的面试氛围也比较轻松，让我感受到的是对技术的探讨和学习。最后就是公司提供的薪资福利还是蛮诱人的，除了高薪酬，还有全额的五险一金（后来公司还为员工缴纳了补充医疗保险，这块我觉得是非常好的），这些对于当时刚来杭州打拼的我来说，是非常重要的。',

            '哇！零壹比特听起来真的很不错诶，那是有什么特别的原因让你从程序猿走向了管理路线呢？',

            '开始我和大多数程序猿有一样的想法“技术走遍天下”，在加入公司后，随着项目的发展，技术的提升和应用很难满足个人的成长规划。同时公司也提倡全面发展，倡导“精英”文化，所以除了专业能力的提升外，我开始接触更多其他方面的工作。公司的技术大牛很多，如何让大家发挥出“1+1>2”的团队价值，成为了我成长的一个突破点，我们开始梳理工作流程，讨论技术方案，分享专业知识，互相影响和监督，共同提升，逐渐形成了一个有战斗力的技术团队。',

            '那现在的工作会给你带来很大的压力吗？',

            '肯定会有压力呀，但是有压力才有动力。我们是以产品为导向，以用户为核心，以共赢为目的。版本更新速度特别快，每周一个版本，每个版本都有核心功能，上线后需要通过数据分析，进行规则的调整，最终实现预期效果。社交产品做全球化会遇到很多难点，例如：相比国内，海外部分地区网络不发达，手机性能较差，很多功能不能友好的支持。为了满足用户需求，我们实现了根据用户的手机和网络，动态的分配相关参数，大幅度的提升了用户体验。',

            '好的，感谢章章总的分享，最后你有什么想对大家说的吗？',

            '计算机能以原始符号0和1，创造一个丰富多彩的互联网的时代，零壹比特以“零壹”为主题，寓意我们仰望星空，脚踏实地，能够服务更多的用户',
          ],
        },
        {
          imgURl: require('../assets/images/gushi_pic2.png'),
          title: '漂洋过海回国看上“你”——商务经理张赫',
          content: [
            '听说你之前在两个国家学习生活过，可以分享下你之前在海外的一些经历吗？',
            '是的，在白俄罗斯完成学业后，我选择了去加拿大进修，并在加拿大工作和创业。在这期间还游历了欧美诸国，实践中了解和学习了不少东西。',
            '哇，好羡慕！现在因为疫情都没有机会出去了，那之后为什么又选择了回到了祖国的怀抱呢？',
            '我其实很看好国内的发展前景，也深知在国外跨越种族鸿沟时，还需要付出很多本不必要的心血。',
            '明白，那回国后在选择工作的时候，你会比较看重什么呢？',
            '最看重的是工作中文化和能力的认同吧，像零壹比特的文化还是比较鼓励和认可员工所做出的成绩，当然在奖金上也是毫不吝啬的哈哈。',
            '你眼中的零壹比特是一家什么样的互联网公司呢？',
            '公司的主要产品呢在行业内都是处于领先的地位，业内只要一提这几个产品的名字，不能说是如雷贯耳，但也是家喻户晓。公司的人才水平也是非常不错的，我们始终强调精英文化，未来也只考虑引入精英。像我们海外商务部的小伙伴们，都是在海外学习、工作甚至生活过，其他部门的海归人才比例也是相当高的。当然，人才对标的薪酬福利也是相当不错的，以我对市场的了解，绝对高于市场水平一大截。',
            '听起来真的很不错哦，那你觉得零壹比特的成长空间怎么样呢？',
            '首先我觉得工作内容特别丰富和有趣，可以接触不同地区的人和文化，并且可以学到很多不一样的知识。业务开拓过程也挺锻炼人的，经过一段时间的历练，明显感觉自己在各方面的能力都有了明显的提升。此外，公司给个人发挥的空间还是比较大的，没有那种领导要怎样就一定要怎样的僵化思维，开放和开明程度还是比较高的。总之是一家既能赚钱又能涨经验的公司吧。（此处省略一万个哈哈哈哈的笑声）',
            '那零壹比特确实是一家比较合适“海龟”们的公司哦，可以聊聊工作氛围怎么样吗？',
            '那大家相处肯定是非常融洽的，也没有什么勾心斗角的。leader会比较重视我们的想法，不会有那种“独裁专制”的 。而且我们的人力小姐姐也是非常贴心的，对我们都非常的照顾和关心。',
            '感觉真的很棒哦，那你觉得公司目前有什么方面让你觉得还需要改进下的呢？',
            '这个还真不好说，唯一想吐槽的应该是所处的园区门口，一到早高峰就堵车，堵汽车啊堵电动车啥的。不过我是坐地铁来的，也没啥影响哈哈。总体而言，零壹比特个方面都还是很不错的，是一个值得长时间在此坚守和工作下去的地方。当然，也欢迎各路海外留学的伙伴们可以加入我们，也可以来找我内推哦。',
          ],
        },
      ],
    }
  },
  mounted() {
    console.log(this.$route.params.id)
  },
}
</script>

<style lang="less" scoped>
.img_box {
  @media (max-width: 700px) {
    // height:1.8rem
  }
  width: 100%;
}
.story_box {
  @media (max-width: 700px) {
    padding: 0.12rem 0.24rem;
  }
  padding: 1rem 2.8rem;
}
.story_title {
  @media (max-width: 700px) {
    font-size: 0.14rem;
    margin-bottom: 0.2rem;
  }
  font-family: 'AlimamaShuHeiTi-Bold';
  font-size: 0.3rem;
  color: #183266;
  margin-bottom: 0.4rem;
}
.story_content {
  @media (max-width: 700px) {
    font-size: 0.14rem;
    line-height: 0.2rem;
  }
  font-size: 0.18rem;
  font-weight: 400;
  color: #79849b;
  line-height: 0.3rem;
}
.paragraph {
  @media (max-width: 700px) {
    margin-bottom: 0.12rem;
    padding: 0.1rem;
  }
  width: max-content;
  max-width: 100%;
  padding: 0.2rem;
  color: #79849b;
  background: rgba(14, 86, 228, 0.05);
  border-radius: 0 12px 12px 12px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 0.18rem;
  text-align: justify;
}
.double_p {
  background: rgba(14, 86, 228, 0.15);
}
.p_icon {
  position: absolute;
  top: 0;
  left: -0.1rem;
  width: 0.11rem;
  height: 0.15rem;
}
.mobile_icon {
  position: absolute;
  top: 0;
  right: -0.11rem;
  width: 0.11rem;
  height: 0.15rem;
}
.mobile_p {
  width: 78.93%;
}
.single_mobile_p {
  float: left;
}
.double_mobile_p {
  float: right;
  border-radius: 12px 0 12px 12px;
}
</style>
