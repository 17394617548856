import Vue from 'vue'
import App from './App.vue'
import './modules/vant.js'
import 'windi.css'
import './assets/css/common.css'
import router from './router'

console.log(window.screen.width,window.screen.height,window.screenX,window.screenY);
const flag = (window.screen.width < window.screen.height)
// alert(flag)

Vue.prototype.$isMobile = flag


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
