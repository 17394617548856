import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/info',
    redirect: '/',
  },
  {
    path: '/culture',
    redirect: '/',
  },
  {
    path: '/team',
    redirect: '/',
  },
  {
    path: '/story',
    redirect: '/',
  },
  {
    path: '/employee_story',
    redirect: '/',
  },
  {
    path: '/employee_story',
    name: 'employee_story',
    component: () => import(/* webpackChunkName: "employee_story" */ '../views/EmployeeStory.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "product" */ '../views/Product.vue')
  },
  {
    path: '/chamet',
    redirect: '/product',
  },
  {
    path: '/gemgala',
    redirect: '/product',
  },
  {
    path: '/game',
    redirect: '/product',
  },
  {
    path: '/server',
    redirect: '/product',
  },
  {
    path: '/join_us',
    name: 'join_us',
    component: () => import(/* webpackChunkName: "join_us" */ '../views/JoinUs.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes,

})
router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop=0
  window.scrollTo(0,0)
  next()
})


export default router
