var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.$isMobile)?_c('div',{staticClass:"mt-100px product_container"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3)]):_c('div',{staticClass:"product_container m_product_container"},[_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-center",attrs:{"id":"chamet"}},[_c('div',{staticClass:"content_box"},[_c('div',{staticClass:"content_title"},[_vm._v("社交")]),_c('div',{staticClass:"content"},[_vm._v(" 在出海社交赛道上，我们主打社交游戏化理念，我们定位在海外市场的泛娱乐社交产品上，让我们更加专注，在竞争激烈的红海中，从追赶者逐渐成为领跑者。我们的产品于2019年12月开始上线运营，目前主要服务的用户地区有南亚、中东北非、东南亚、拉美、欧美，覆盖全球100多国家。 3年来我们以技术研发为基础，不断地打磨产品。先后推出视频聊、随机匹配聊天、直播及PK、多人连线直播、游戏语音房、兴趣群聊等互动形式，深受用户喜爱。目前在多个国家或地区畅销榜上位居前列。 结合货币化社交方式，在全球新冠疫情期间，我们的产品帮助了许许多多的失业青年，重新获得收入来源，摆脱困境。获得了无数用户的好评和力荐。 ")])]),_c('img',{staticClass:"img",attrs:{"src":require("../assets/images/paoduct1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-center",attrs:{"id":"gemgala"}},[_c('img',{staticClass:"img",attrs:{"src":require("../assets/images/paoduct2.png"),"alt":""}}),_c('div',{staticClass:"content_box content_box1"},[_c('div',{staticClass:"content_title"},[_vm._v("网赚")]),_c('div',{staticClass:"content"},[_vm._v(" 在全球全民在线的移动互联网环境下，大量的个人在网时间被浪费了。为了打破这个困境，我们推出了相应的网赚产品。产品主打让空闲时间更有价值，努力实现用户、广告主和平台的多方共赢。 产品推出短短半年时间，就获得海外100万+的用户量，并在菲律宾、巴基斯坦等地区跻身下载榜单前3名。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-center",attrs:{"id":"game"}},[_c('div',{staticClass:"content_box"},[_c('div',{staticClass:"content_title"},[_vm._v("游戏")]),_c('div',{staticClass:"content"},[_vm._v(" 依托丰富的产品开发和运营经验，我们开发了多款符合好友互动的竞技类游戏，一经推出深受用户喜欢。 ")])]),_c('img',{staticClass:"img",attrs:{"src":require("../assets/images/paoduct3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-center",attrs:{"id":"server"}},[_c('img',{staticClass:"img",attrs:{"src":require("../assets/images/paoduct4.png"),"alt":""}}),_c('div',{staticClass:"content_box content_box1"},[_c('div',{staticClass:"content_title"},[_vm._v("服务")]),_c('div',{staticClass:"content"},[_vm._v(" 我们利用自身的产品和技术优势，并结合多年的出海经验，将自己能力提炼出来形成服务，目前已开发出可为合作伙伴在海外进行收付款业务的服务。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"img",attrs:{"src":require("../assets/images/mobile_product1.png"),"alt":""}}),_c('div',{staticClass:"content_box"},[_c('div',{staticClass:"content_title"},[_vm._v("社交")]),_c('div',{staticClass:"content"},[_vm._v(" 在出海社交赛道上，我们主打社交游戏化理念，我们定位在海外市场的泛娱乐社交产品上，让我们更加专注，在竞争激烈的红海中，从追赶者逐渐成为领跑者。我们的产品于2019年12月开始上线运营，目前主要服务的用户地区有南亚、中东北非、东南亚、拉美、欧美，覆盖全球100多国家。 3年来我们以技术研发为基础，不断地打磨产品。先后推出视频聊、随机匹配聊天、直播及PK、多人连线直播、游戏语音房、兴趣群聊等互动形式，深受用户喜爱。目前在多个国家或地区畅销榜上位居前列。 结合货币化社交方式，在全球新冠疫情期间，我们的产品帮助了许许多多的失业青年，重新获得收入来源，摆脱困境。获得了无数用户的好评和力荐。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"img",attrs:{"src":require("../assets/images/mobile_product2.png"),"alt":""}}),_c('div',{staticClass:"content_box"},[_c('div',{staticClass:"content_title"},[_vm._v("网赚")]),_c('div',{staticClass:"content"},[_vm._v(" 在全球全民在线的移动互联网环境下，大量的个人在网时间被浪费了。为了打破这个困境，我们推出了相应的网赚产品。产品主打让空闲时间更有价值，努力实现用户、广告主和平台的多方共赢。 产品推出短短半年时间，就获得海外100万+的用户量，并在菲律宾、巴基斯坦等地区跻身下载榜单前3名。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"img",attrs:{"src":require("../assets/images/mobile_product3.png"),"alt":""}}),_c('div',{staticClass:"content_box"},[_c('div',{staticClass:"content_title"},[_vm._v("游戏")]),_c('div',{staticClass:"content"},[_vm._v(" 依托丰富的产品开发和运营经验，我们开发了多款符合好友互动的竞技类游戏，一经推出深受用户喜欢。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"img",attrs:{"src":require("../assets/images/mobile_product4.png"),"alt":""}}),_c('div',{staticClass:"content_box"},[_c('div',{staticClass:"content_title"},[_vm._v("服务")]),_c('div',{staticClass:"content"},[_vm._v(" 我们利用自身的产品和技术优势，并结合多年的出海经验，将自己能力提炼出来形成服务，目前已开发出可为合作伙伴在海外进行收付款业务的服务。 ")])])])
}]

export { render, staticRenderFns }