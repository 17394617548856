<template>
  <div>
    <div v-if="!$isMobile" class="mt-100px product_container">
      <div id="chamet" class="flex-center">
        <div class="content_box">
          <div class="content_title">社交</div>
          <div class="content">
            在出海社交赛道上，我们主打社交游戏化理念，我们定位在海外市场的泛娱乐社交产品上，让我们更加专注，在竞争激烈的红海中，从追赶者逐渐成为领跑者。我们的产品于2019年12月开始上线运营，目前主要服务的用户地区有南亚、中东北非、东南亚、拉美、欧美，覆盖全球100多国家。
            3年来我们以技术研发为基础，不断地打磨产品。先后推出视频聊、随机匹配聊天、直播及PK、多人连线直播、游戏语音房、兴趣群聊等互动形式，深受用户喜爱。目前在多个国家或地区畅销榜上位居前列。
            结合货币化社交方式，在全球新冠疫情期间，我们的产品帮助了许许多多的失业青年，重新获得收入来源，摆脱困境。获得了无数用户的好评和力荐。
          </div>
        </div>
        <img class="img" src="../assets/images/paoduct1.png" alt="" />
      </div>
      <div id="gemgala" class="flex-center">
        <img class="img" src="../assets/images/paoduct2.png" alt="" />

        <div class="content_box content_box1">
          <div class="content_title">网赚</div>
          <div class="content">
            在全球全民在线的移动互联网环境下，大量的个人在网时间被浪费了。为了打破这个困境，我们推出了相应的网赚产品。产品主打让空闲时间更有价值，努力实现用户、广告主和平台的多方共赢。
            产品推出短短半年时间，就获得海外100万+的用户量，并在菲律宾、巴基斯坦等地区跻身下载榜单前3名。
          </div>
        </div>
      </div>
      <div id="game" class="flex-center">
        <div class="content_box">
          <div class="content_title">游戏</div>
          <div class="content">
            依托丰富的产品开发和运营经验，我们开发了多款符合好友互动的竞技类游戏，一经推出深受用户喜欢。
          </div>
        </div>
        <img class="img" src="../assets/images/paoduct3.png" alt="" />
      </div>
      <div id="server" class="flex-center">
        <img class="img" src="../assets/images/paoduct4.png" alt="" />
        <div class="content_box content_box1">
          <div class="content_title">服务</div>
          <div class="content">
            我们利用自身的产品和技术优势，并结合多年的出海经验，将自己能力提炼出来形成服务，目前已开发出可为合作伙伴在海外进行收付款业务的服务。
          </div>
        </div>
      </div>
    </div>
    <!-- 移动端 -->
    <div v-else class="product_container m_product_container">
      <div>
        <img class="img" src="../assets/images/mobile_product1.png" alt="" />
        <div class="content_box">
          <div class="content_title">社交</div>
          <div class="content">
            在出海社交赛道上，我们主打社交游戏化理念，我们定位在海外市场的泛娱乐社交产品上，让我们更加专注，在竞争激烈的红海中，从追赶者逐渐成为领跑者。我们的产品于2019年12月开始上线运营，目前主要服务的用户地区有南亚、中东北非、东南亚、拉美、欧美，覆盖全球100多国家。
            3年来我们以技术研发为基础，不断地打磨产品。先后推出视频聊、随机匹配聊天、直播及PK、多人连线直播、游戏语音房、兴趣群聊等互动形式，深受用户喜爱。目前在多个国家或地区畅销榜上位居前列。
            结合货币化社交方式，在全球新冠疫情期间，我们的产品帮助了许许多多的失业青年，重新获得收入来源，摆脱困境。获得了无数用户的好评和力荐。
          </div>
        </div>
      </div>
      <div>
        <img class="img" src="../assets/images/mobile_product2.png" alt="" />
        <div class="content_box">
          <div class="content_title">网赚</div>
          <div class="content">
            在全球全民在线的移动互联网环境下，大量的个人在网时间被浪费了。为了打破这个困境，我们推出了相应的网赚产品。产品主打让空闲时间更有价值，努力实现用户、广告主和平台的多方共赢。
            产品推出短短半年时间，就获得海外100万+的用户量，并在菲律宾、巴基斯坦等地区跻身下载榜单前3名。
          </div>
        </div>
      </div>
      <div>
        <img class="img" src="../assets/images/mobile_product3.png" alt="" />
        <div class="content_box">
          <div class="content_title">游戏</div>
          <div class="content">
            依托丰富的产品开发和运营经验，我们开发了多款符合好友互动的竞技类游戏，一经推出深受用户喜欢。
          </div>
        </div>
      </div>
      <div>
        <img class="img" src="../assets/images/mobile_product4.png" alt="" />
        <div class="content_box">
          <div class="content_title">服务</div>
          <div class="content">
            我们利用自身的产品和技术优势，并结合多年的出海经验，将自己能力提炼出来形成服务，目前已开发出可为合作伙伴在海外进行收付款业务的服务。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Product',
}
</script>

<style lang="less" scoped>
.product_container {
  @media (max-width: 700px) {
    margin-top: 0.44rem;
    padding-bottom: 1rem;
  }
  width: 100%;
  height: 100%;
  padding-bottom: 0.1rem;
}
.flex-center {
  width: 100%;
}
.m_product_container {
  .content {
    margin-bottom: 0.24rem;
  }
}
.img {
  @media (max-width: 700px) {
    width: 100%;
    background: none;
  }
  width: 51.56%;
  // width: 990px;
  // height: 100%;
  // background: url('../assets/images/paoduct1.png');
  // background-size: 100%;
  // background-repeat: no-repeat;
}
.img2 {
  background: url('../assets/images/paoduct2.png');
  background-size: 100%;
  background-repeat: no-repeat;
}
.img3 {
  background: url('../assets/images/paoduct3.png');
  background-size: 100%;
  background-repeat: no-repeat;
}
.img4 {
  background: url('../assets/images/paoduct4.png');
  background-size: 100%;
  background-repeat: no-repeat;
}
.content_box {
  @media (max-width: 1500px) {
    padding: 0 0 0 2rem;
  }
  @media (max-width: 700px) {
    width: 100%;
    min-width: 100%;
    padding: 0 0.24rem;
  }
  // width: 930px;
  width: 50%;
  // min-width: 9.3rem;
  padding: 0 0 0 2.8rem;
}
.content_box1 {
  padding: 0rem 2.8rem 0 0;
}
.content_title {
  @media (max-width: 1500px) {
    padding-bottom: 0.1rem;
  }
  @media (max-width: 700px) {
    font-size: 0.15rem;
    padding-bottom: 0.11rem;
  }
  color: #183266;
  font-family: 'AlimamaShuHeiTi-Bold';
  padding-bottom: 30px;
  font-size: 0.3rem;
}
.content {
  @media (max-width: 700px) {
    font-size: 0.12rem;
    line-height: 0.2rem;
  }
  font-size: 0.18rem;
  font-weight: 400;
  color: #79849b;
  line-height: 0.4rem;
  text-align: justify;
}
</style>
