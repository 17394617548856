<template>
  <div>
    <img class="head" src="../assets/images/join_1.png" alt="" />
    <div class="position_box">
      <div class="title">探索你感兴趣的职位</div>
      <!-- <a :href=linkUrl> -->
      <div @click="toBoss" class="to-boss-box" :class="{ 'flex-center': !$isMobile }">
          <img v-if="!$isMobile" class="position_detail" src="../assets/images/join_2.png" alt="" />
          <img v-else class="position_detail" src="../assets/images/m_join_2.png" alt="" />
          <img
            v-if="!$isMobile"
            class="position_detail position_detail1"
            src="../assets/images/join_3.png"
            alt=""
          />
          <img
            v-else
            class="position_detail position_detail1"
            src="../assets/images/m_join_3.png"
            alt=""
          />
        </div>
      <!-- </a> -->
    </div>
    <div :class="{ 'mb-250px': !$isMobile, 'mb-24px': $isMobile }">
      <div class="title">应聘联系方式</div>
      <div :class="{ 'flex-center': !$isMobile }">
        <img class="w-670px mr-19px" src="../assets/images/join_4.png" alt="" />
        <a v-show="$isMobile" @click="callPhone('0571-81022379')" rel="external nofollow">
          <img class="w-670px" src="../assets/images/join_5.png" alt="" />
        </a>
        <a href="" v-show="false" ref="tels"></a>
        <img v-show="!$isMobile" class="w-670px" src="../assets/images/join_5.png" alt="" />

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JoinUs',
  data() {
    return {
      linkUrl: 'https://m.zhipin.com/mpa/html/weijd/weijd-job/0a78ffae8303e3581Xdy3N64E1RQ?date8=20221025&sid=tosee_jd_7d11727147cbfdc91nR92Ny5GVZX&fromSource=2'
    }
  },
  methods: {
    callPhone(phoneNumber) {
      // window.location.href = 'tel://' + phoneNumber
      this.$refs.tels.href = 'tel://' + phoneNumber
      this.$refs.tels.click()
    },
    toBoss() {
      window.open('https://www.zhipin.com/gongsir/ae8a433b6f68f22a1Xd83d67FlE~.html?ka=company-jobs')
    }
  },
}
</script>

<style lang="less" scoped>
.head {
  @media (max-width: 700px) {
    // height: 1.8rem;
  }
  width: 100%;
}
.position_box {
  @media (max-width: 700px) {
    margin: 0.24rem auto;
  }
  margin: 1.2rem auto;
}
.to-boss-box {
  cursor: pointer;
}
.title {
  @media (max-width: 700px) {
    font-size: 0.2rem;
    margin-bottom: 0.12rem;
  }
  font-size: 0.35rem;
  font-family: 'AlimamaShuHeiTi-Bold';
  color: #183266;
  text-align: center;
  margin-bottom: 47px;
}
.position_detail {
  @media (max-width: 700px) {
    width: 3.27rem;
    margin: 0 auto;
  }
  width: 35.36%;
  // width: 679px;
}
.position_detail1 {
  @media (max-width: 700px) {
    width: 3.27rem;
  }
  width: 35.5%;
}
.w-670px {
  @media (max-width: 700px) {
    width: 87.47%;
    margin: 0 auto 0.12rem;
  }
}
</style>
