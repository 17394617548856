<template>
  <div class="container">
    <div class="head_box">
      <img
        v-if="$isMobile"
        class="nav_phone_head w-100%"
        src="../assets/images/phone_head.png"
        alt=""
      />
      <img v-else class="w-100%" src="../assets/images/head.png" alt="" />
      <!-- <Nav /> -->
      <div id="info" class="info_box">
        <span>杭州零壹比特科技有限公司</span>
        是一家致力于海外泛娱乐业务的科技公司，于2019年12月开始孵化出海业务。多年来潜心研发产品，以共赢为经营原则，以领先的信息技术和出色的产品力为依托，打造出优秀的互联网产品。产品用户和合作伙伴遍布世界各地，受到广泛的关注和好评。
      </div>
    </div>
    <!-- 企业文化 -->
    <div id="culture" class="enterprise_culture_box">
      <div class="enterprise_culture_item_box" :class="{ 'dp-f': !$isMobile }">
        <img
          class="enterprise_culture_item_img"
          src="../assets/images/img1.png"
          alt=""
        />
        <div class="flex-center enterprise_culture_item">
          <div>
            <div class="enterprise_culture_item_title">经营原则-共赢</div>
            <div class="enterprise_culture_item_content">
              我们坚信只有共赢才能长久发展。共赢是全面的共赢，是在行业生态内实现共赢，是与合作伙伴实现共赢，是与员工实现共赢。
            </div>
          </div>
        </div>
      </div>
      <div class="enterprise_culture_item_box" :class="{ 'dp-f': !$isMobile }">
        <div class="enterprise_culture_item enterprise_culture_item2">
          <div class="enterprise_culture_item_title">发展战略-创新</div>
          <div class="enterprise_culture_item_content">
            面对市场的复杂性和风险性，面对行业内的剧烈竞争，只有保持创新，我们才能具备生存能力，并为企业发展提供动力。
          </div>
        </div>
        <img
          class="enterprise_culture_item_img enterprise_culture_item_img2"
          src="../assets/images/img2.png"
          alt=""
        />
      </div>
      <div class="enterprise_culture_item_box" :class="{ 'dp-f': !$isMobile }">
        <img
          class="enterprise_culture_item_img"
          src="../assets/images/img3.png"
          alt=""
        />
        <div class="enterprise_culture_item enterprise_culture_item3">
          <div class="enterprise_culture_item_title">人才理念-精英</div>
          <div class="enterprise_culture_item_content">
            我们是对美好生活有向往，对生活品质有追求的互联网弄潮儿。有梦想、有能力的精英团队，一定可以实现我们宏大的目标。
          </div>
        </div>
      </div>
    </div>
    <!-- 团队氛围 -->
    <div id="team" class="team_container">
      <img class="title_img" src="../assets/images/title1.png" alt="" />
      <div class="team_img_box">
        <img class="team_img" src="../assets/images/team1.png" alt="" />
        <img
          class="team_img team_img2"
          src="../assets/images/team2.png"
          alt=""
        />
        <img class="team_img" src="../assets/images/team3.png" alt="" />
        <img class="team_img" src="../assets/images/team4.png" alt="" />
        <img
          v-show="!$isMobile"
          class="team_img"
          src="../assets/images/team5.png?v=1"
          alt=""
        />
        <!-- <img class="team_img" src="../assets/images/team6.png" alt="" /> -->
      </div>
    </div>
    <!-- 员工故事 -->
    <div id="story" class="employee_story_container">
      <img
        class="title_img story_title_img"
        src="../assets/images/title2.png"
        alt=""
      />
      <div class="dp-f jc-c employee_story_box">
        <div
          @click="toStory(1)"
          class="employee_story_item"
          :class="{ web_employee_story_item: !$isMobile }"
        >
          <img
            class="story_img"
            src="../assets/images/gushi_1.png?v=1"
            alt=""
          />
          <div class="story_content_box">
            <div>
              <div class="story_title">
                “不设限”，职场小白变身优秀运营——产品运营部缑智昊
              </div>
              <!-- <div v-if="!$isMobile" class="story_content">
                时间很快，来富聊工作已经4年有余，一次和同学聊起富聊后，临时起兴，写下这篇文章记录我在富的故…
              </div>
              <div v-else class="story_content">
                时间很快，来富聊工作已经4年有余，一次和同学聊起富聊…
              </div> -->
            </div>
          </div>
        </div>
        <div
          @click="toStory(2)"
          class="employee_story_item employee_story_item2"
          :class="{ web_employee_story_item: !$isMobile }"
        >
          <img class="story_img" src="../assets/images/gushi_2.png" alt="" />
          <div class="story_content_box">
            <div>
              <div class="story_title">
                出魔都，弃阿里，和公司一同成长——技术部陈章
              </div>
              <!-- <div v-if="!$isMobile" class="story_content">
                时间很快，来富聊工作已经4年有余，一次和同学聊起富聊后，临时起兴，写下这篇文章记录我在富的故…
              </div>
              <div v-else class="story_content">
                时间很快，来富聊工作已经4年有余，一次和同学聊起富聊…
              </div> -->
            </div>
          </div>
        </div>
        <div
          @click="toStory(3)"
          class="employee_story_item"
          :class="{ web_employee_story_item: !$isMobile }"
        >
          <img class="story_img" src="../assets/images/gushi_3.png" alt="" />
          <div class="story_content_box">
            <div>
              <div class="story_title">漂洋过海回国看上“你”——商务经理张赫</div>
              <!-- <div v-if="!$isMobile" class="story_content">
                时间很快，来富聊工作已经4年有余，一次和同学聊起富聊后，临时起兴，写下这篇文章记录我在富的故…
              </div>
              <div v-else class="story_content">
                时间很快，来富聊工作已经4年有余，一次和同学聊起富聊…
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from '../components/Nav.vue'
export default {
  name: 'Home',
  props: {
    msg: String,
  },
  components: {
    Nav,
  },
  data() {
    return {
      // isMobile: localStorage.getItem('mobile'),
    }
  },
  mounted() {},
  methods: {
    toStory(id) {
      this.$router.push({
        name: 'employee_story',
        params: { id: id },
      })

      // let routeUrl = this.$router.resolve({
      //   path: "/employee_story",
      //   query: { id: id}
      // })
      // window.open(routeUrl.href, '_blank')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.container {
  width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  background: #f6f6f6;
}
.head_box {
  @media (max-width: 1500px) {
    margin-bottom: 2rem;
  }
  @media (max-width: 700px) {
    width: 100vw;
    margin-bottom: 1.1rem;
  }
  width: 100%;
  margin-bottom: 2.6rem;
  position: relative;
  img {
    width: 100%;
  }
}
.nav_phone_head {
  width: 100%;
}
.info_box {
  @media (max-width: 1500px) {
    // bottom: 10%;
    padding: 0.3rem 0.5rem;
  }
  @media (max-width: 700px) {
    width: 93.6%;
    font-size: 14px;
    padding: 0.12rem 0.18rem;
    border-radius: 3px;
    // bottom: 0.2rem;
    line-height: 0.21rem;
  }
  line-height: 0.4rem;
  width: 70%;
  background: #fff;
  box-shadow: 0px 10px 30px 0px rgba(75, 148, 223, 0.15);
  border-radius: 12px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  padding: 0.58rem 0.6rem;
  box-sizing: border-box;
  font-size: 0.18rem;
  color: #79849b;
  span {
    @media (max-width: 1400px) {
      // font-size: 35px;
    }
    @media (max-width: 700px) {
      font-size: 14px;
    }
    font-weight: bold;
    font-size: 0.2rem;
    color: #185dff;
  }
}
// 企业文化
.enterprise_culture_item_box {
  @media (max-width: 700px) {
    height: 0.44rem;
  }
  width: 100%;
  height: 6.04rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.enterprise_culture_item_box {
  @media (max-width: 1500px) {
    margin-bottom: 1rem;
  }
  @media (max-width: 700px) {
    margin-bottom: 0.24rem;
  }
  margin-bottom: 1.6rem;
  height: max-content;
}
.enterprise_culture_item_img {
  width: 52.08%;
}
.enterprise_culture_item {
  @media (max-width: 1500px) {
    padding: 4.5% 13% 4.5% 4%;
  }
  @media (max-width: 1100px) {
    padding: 3.5% 13% 3.5% 4%;
  }
  @media (max-width: 700px) {
    padding: 0.13rem 0.12rem 0.13rem 0.06rem;
  }
  width: 47.71%;
  // height: 20%;
  max-height: 5.14rem;
  background: linear-gradient(140deg, #e6d125 0%, #ffaa0c 100%);
  opacity: 0.66;
  // padding: 1.14rem 2.8rem 1.1rem 0.61rem;
  padding: 6.5% 13% 6.5% 4%;
  color: #fff;
}
.enterprise_culture_item2 {
  @media (max-width: 1500px) {
    padding: 4.5% 4% 4.5% 13%;
  }
  @media (max-width: 1100px) {
    padding: 3.5% 4% 3.5% 13%;
  }
  @media (max-width: 700px) {
    padding: 0.13rem 0.12rem 0.13rem 0.06rem;
  }
  background: linear-gradient(135deg, #f69a59 0%, #f66c59 100%);
  width: 47.71%;
  text-align: right;
  // padding: 1.08rem 0.55rem 0.99rem 2.8rem;
  padding: 6.5% 4% 6.5% 13%;
}
.enterprise_culture_item3 {
  background: linear-gradient(135deg, #0eb0e4 0%, #0e54e4 100%);
}
.enterprise_culture_item_title {
  @media (max-width: 1100px) {
    margin-bottom: 0.1rem;
  }
  @media (max-width: 900px) {
    font-size: 0.2rem;
    margin-bottom: 0.1rem;
  }
  @media (max-width: 700px) {
    font-size: 0.12rem;
    margin-bottom: 0.03rem;
  }
  font-size: 0.3rem;
  font-family: 'AlimamaShuHeiTi-Bold';
  margin-bottom: 0.3rem;
}
.enterprise_culture_item_content {
  @media (max-width: 900px) {
    font-size: 0.16rem;
  }
  @media (max-width: 700px) {
    font-size: 0.16rem;
    zoom: 0.5;
  }
  font-size: 0.2rem;
  text-align: justify;
}
// 团队氛围
.team_container {
  @media (max-width: 700px) {
    margin-bottom: 0.36rem;
  }
  margin-bottom: 1.6rem;
}
.title_img {
  @media (max-width: 700px) {
    width: 2.36rem;
    height: 0.26rem;
    margin: 0 auto 0.24rem;
  }
  width: 5.36rem;
  height: 0.61rem;
  margin: 0 auto 0.6rem;
}
.story_title_img {
  @media (max-width: 700px) {
    width: 1.62rem;
    height: 0.23rem;
  }
  width: 3.12rem;
  height: 0.42rem;
}
.team_img_box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.team_img {
  @media (max-width: 700px) {
    margin-bottom: 0.02rem;
    width: 49.6%;
  }
  width: 19.74%;
  height: 100%;
  object-fit: cover;
  // margin-bottom: 0.1rem;
  // height: 6.33rem;
}
// 员工故事
.employee_story_container {
  @media (max-width: 700px) {
    padding-bottom: 0.84rem;
  }
  padding-bottom: 2.73rem;
}
.employee_story_box {
  @media (max-width: 700px) {
    margin: 0.36rem auto 0;
  }
  margin: 0.65rem auto 0;
}
.employee_story_item {
  @media (max-width: 700px) {
    width: 1.17rem;
    height: 1.6rem;
  }
  width: 18.3%;
  // height: 6rem;
  cursor: pointer;
  transition: all 0.5s;
}
.web_employee_story_item:hover {
  transform: scale(1.1);
}
.story_img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.employee_story_item {
  position: relative;
}
.employee_story_item2 {
  @media (max-width: 700px) {
    margin: 0 0.06rem;
  }
  margin: 0 5%;
}
.story_content_box {
  width: 100%;
  display: flex;
  justify-content: center;
}
.story_content_box > div {
  @media (max-width: 700px) {
    // width: 1.11rem;
    // height: 0.83rem;
    padding: 0.06rem 0.06rem 0.12rem;
    bottom: -0.41rem;
  }
  position: absolute;
  bottom: -0.49rem;
  width: 89.7%;
  // width: 3.8rem;
  // height: 1.47rem;
  background: #ffffff;
  box-shadow: 0px 10px 30px 0px rgba(75, 148, 223, 0.15);
  border-radius: 6px;
  padding: 0.12rem 0.21rem 0.18rem;
  box-sizing: border-box;
}
.story_content_box:hover {
}
.story_title {
  @media (max-width: 1000px) {
    font-size: 0.18rem;
    zoom: 0.5;
  }
  @media (max-width: 700px) {
    font-size: 0.2rem;
    zoom: 0.5;
  }
  color: #183266;
  font-size: 0.18rem;
  font-family: 'AlimamaShuHeiTi-Bold';
  display: -webkit-box;
  overflow: hidden;
  /*! autoprefixer: off; */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.story_content {
  @media (max-width: 700px) {
    font-size: 0.2rem;
    zoom: 0.5;
  }
  color: #79849b;
  font-size: 0.2rem;
}
</style>
